<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Vehicles
                        <v-spacer></v-spacer>
                        <add-button @action="form.dialog = true,getDrivers(), $refs.form ? $refs.form.reset(): ''"
                                    permission="vehicle-create">
                            Add Vehicle
                        </add-button>
                    </v-card-title>

                    <v-data-table
                            :headers="headers"
                            :footer-props="footerProps"
                            :items="form.items.data"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading"
                            :options.sync="pagination"
                    >
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td>{{ item.name.toUpperCase() }}</td>
                                <td>{{ item.vehicle_no }}</td>
                                <td>{{ item.tipo.toUpperCase() }}</td>
                                <td>{{ item.seats }}</td>
                                <td>{{ item.driver? item.driver.name :'-' }}</td>
                                <td>{{ item.driver? item.driver.primary_mobile :'-' }}</td>
                                <!-- <td>
                                    <v-chip :input-value="true" label outlined small v-for="route in item.routes" :key="route.id"
                                            color="success" class="mr-1">{{ route.name }}
                                    </v-chip>
                                </td> -->
                                <td class="text-right" style="padding: 0 9px !important; min-width: 100px">
                                    <div class="btn_group">
                                        <edit-button permission="vehicle-update" @agree="form.edit(item)"/>
                                        <delete-button permission="vehicle-delete" @agree="form.delete(item.id)"/>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>

                </v-card>
            </v-flex>

            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1 pa-3">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                v-model="valid"
                                :lazy-validation="lazy"
                        >
                        <v-container fluid grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs12 sm12>
                                    <v-text-field outlined dense autocomplete="off" label="Vehicle name*" required
                                                  v-model="form.name" name="vehicle"
                                                  :error-messages="form.errors.get('name')"
                                                  :rules="[v => !!v || 'Vechicle Name Field is required']"
                                    />
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field outlined dense autocomplete="off" label="Vehicle no*" required
                                                  v-model="form.vehicle_no" name="discount"
                                                  :error-messages="form.errors.get('vehicle_no')"
                                                  :rules="[v => !!v || 'Vehicle No Field is required']"
                                    />
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field outlined dense autocomplete="off" required label="Total seats"
                                                  v-model="form.seats" name="discount"
                                                  :error-messages="form.errors.get('seats')" type="number"
                                                  :rules="[v => !!v || 'Total seats No Field is required']"
                                    />
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-autocomplete
                                            v-model="form.driver_id"
                                            :items="items"
                                            :search-input.sync="search"
                                            :loading="isLoading"
                                            hide-no-data
                                            :error-messages="form.errors.get('driver_id')"
                                            item-text="Name"
                                            item-value="id"
                                            label="Driver"
                                            placeholder="Start typing to Search"
                                            return-object outlined dense></v-autocomplete>
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-select :items="types" required class="pa-0" label="Select bus type*"
                                              v-model="form.tipo"
                                              :error-messages="form.errors.get('tipo')"
                                              name="subject_id" outlined dense/>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </v-layout>
    </v-container>
</template>
<style scoped></style>
<script>

    import {mapState} from 'vuex';
    import Form from '@/library/Form';
    import Mixins from '@/library/Mixins';
    export default {
        mixins: [Mixins],
        name: 'VehicleComponent',
        components: {
        },
        data: () => ({
            valid: true,
            lazy: false,
            isLoading: false,
            form: new Form({
                name: '',
                vehicle_no: '',
                tipo: '',
                seats: '',
                driver_id: '',
            }, 'api/vehicle'),
            headers: [
                {text: '#', align: 'left', value: 'sno', sortable: false, width: 20},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Vehicle No.', align: 'left', value: 'serial', sortable: false},
                {text: 'Type', align: 'left', value: 'type', sortable: false},
                {text: 'Seat', align: 'left', value: 'seat', sortable: false},
                {text: 'Driver', align: 'left', value: 'driver', sortable: false},
                {text: 'Driver Ph No.', align: 'left', value: 'primary_mobile', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            pagination: {
                rowsPerPage: 25
            },
            types: [
                {text: 'Bus', value: 'bus'},
                {text: 'Van', value: 'van'},
                {text: 'Micro', value: 'micro'}
            ],
            drivers: [],
            search: '',
            entries: []
        }),
        watch: {
            search(val) {
                if (!val) return;
                if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=driver&search=' + val)
                    .then(({data}) => {
                        this.entries = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
            'pagination': function () {
                this.get();
            }
        },
        computed: {
            ...mapState({
                vehicles: 'records',
                loading: 'loading'
            }),
            items() {
                return this.entries.map(entry => {
                    return Object.assign({}, entry, {Name: entry.name})
                })
            }
        },
        mounted() {
            // this.get();
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.store()
                }
            },
            get(params) {
                let extraParams = 'rows=30';
                let query = [null, undefined].includes(params) ? this.queryString(extraParams) : params;
                this.form.get(null, query).then(({data}) => {
                    // this.pagination.totalItems = data.meta.total
                })
            },
            store() {
                this.form.driver_id = this.form.driver_id.id;
                this.form.store();
            },
            getDrivers() {
                this.$rest.get('/api/member?type=driver').then(({data}) => {
                    this.drivers = data.data.map(driver => {
                        return {text: driver.name, value: driver.id};
                    });
                })
            }
        },
    }
</script>
